<template>
    <v-main>

      <v-container v-if="message">
        {{ $router }}
      </v-container>       
      {{ $route.params.message }}     

      <!-- Table data public -->
      <v-container
        class="admincontainer"
        v-if="user && user.id && (user.type == 'admin' || user.type == 'editor')"              
        >
        <v-row
          v-if="user.name"
          class="mb-8"          
          >
          <v-col
            cols="2"
            md="2"             
            >                      
            <sidemenu></sidemenu>       
          </v-col>

          <v-col
            cols="10"
            md="10" 
            >                 
              <div class="ml-4 mt-8 mb-4">         
                <h2>Users
                  
                <v-btn
                  depressed
                  small
                  class="userdisplay ml-2"                  
                  @click="openadduser = !openadduser;setPartner()"
                >                  
                  <v-icon
                    right
                    dark                    
                    class="mr-2 mt-0"
                    >
                    mdi-plus
                  </v-icon>
                  <div class="pt-1">Add user</div>
                </v-btn>              
              
              </h2>              
              </div>                       
                <Transition name="fade">
                <v-form v-model="valid" ref="form">
                <v-row
                  class="mx-1"
                  v-show="openadduser"
                  >                  
                  <v-col
                    cols="6"                    
                    >                                        
                      <v-text-field
                        v-model="adduser.email"                                                       
                        :rules="[rules.email]"
                        placeholder="Email"
                        prepend-icon="mdi-at"                        
                        class=""
                      ></v-text-field>   
                                            
                      <v-autocomplete
                        v-if="user.type == 'admin'"
                        v-model="adduser.partner_id"
                        :items="partners"                       
                        dense                        
                        item-value="id"
                        item-text="name"                  
                        placeholder="Select institution"
                        class="mt-0 ml-8 mb-0 py-2"
                        background-color="#f7f7f7"                       
                        outlined
                        >
                        <template v-slot:item='{item}'> <div v-html='item.name'/></template>
                        <template v-slot:selection='{item}'> <div v-html=' item.name.length > 40 ? item.name.substring(0,40)+"..." : item.name '/></template>
                      </v-autocomplete>                                                       
                      <v-text-field
                        v-else-if="user.type == 'editor'"                        
                        v-model="adduser.partner_id"
                        style="visibility:hidden;height:0"                                                                       
                      ></v-text-field>                       
                                                                                              
                    </v-col> 

                    <v-col
                      cols="4"
                      >
                      <v-text-field
                        v-if="user.type == 'editor' || user.type == 'admin'"
                        :rules="[rules.required]"
                        placeholder="Name"
                        v-model="adduser.name"                        
                      ></v-text-field>  
                    </v-col>                    
                    <v-col
                    cols="2"                           
                    >                          
                    <v-btn
                        depressed
                        small                            
                        class="mt-4"                        
                        :disabled="!valid"
                        @click="saveUser"
                      >                  
                        <div class="pt-1">Add new editor</div>                        
                      </v-btn>
                  </v-col>                 
                </v-row>   
                </v-form>                      
                </Transition>
              <div>         
                <v-data-table                    
                  :headers="headers"
                  :items="filteredUsers"
                  :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                  }"
                  :items-per-page="30"
                  :single-expand="true"
                  :search="search"           
                  :custom-filter="filterPartner"       
                  show-expand
                  :expanded.sync="expanded"
                  item-key="id"
                  class="elevation-1 researchtable"
                  >
                  <template v-slot:item="{ item }">
                      <tr>        
                        <td>
                          <span v-if="item['active']" class="token">active</span>                          
                        </td>                
                        <td>
                          {{ item.name }}
                        </td>
                        <td>
                          <div v-if="item['partner']">
                            {{item['partner'][0].name }}
                          </div>                          
                        </td>
                        <td class="d-block d-sm-table-cell">
                          <div v-if="item['teams']">
                            {{item['teams'][0].name }}
                          </div>                          
                        </td>
                        <td>                                              
                          <!--
                          <span v-if="item['token']" class="token">T</span>                                                            
                          <span v-if="item['pw']" class="token">pw</span>                          
                          -->
                          {{ item.email }}                          
                        </td>
                        <td>                                                    
                            
                            <v-menu
                              bottom
                              left
                              style="display:inline-block"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  dark
                                  depressed
                                  color="rgba(0, 0, 0, 0.87)"
                                  icon
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                              </template>

                              <v-list
                                dense                                
                                >
                                <v-list-item>
                                  <v-list-item-title>delete</v-list-item-title>
                                  <v-icon                
                                    size="14"
                                    color="gray"
                                    class="px-0 py-0 ml-2 mr-0 delete"                                                                   
                                    style="display:inline-block"
                                    v-if="(user.type === 'editor' && item.partner_id === user.partner_id) || (user.type === 'admin')"
                                    @click="deleteUser(item.id)"
                                    >
                                    mdi-trash-can-outline
                                  </v-icon>                                                  
                                </v-list-item>
                                <!--
                                <v-list-item>
                                  <v-list-item-title>edit</v-list-item-title>
                                  <v-icon                
                                    size="14"
                                    color="gray"
                                    class="px-0 py-0 mt-0 ml-2 mr-0 delete"                                                                   
                                    style="display:inline-block"
                                    v-if="(user.type === 'editor' && item.partner_id === user.partner_id) || (user.type === 'admin')"
                                    @click="editUser(item.id)"
                                    >
                                    mdi-pencil
                                  </v-icon>                                                                                    
                                </v-list-item>
                                -->
                              </v-list>
                            </v-menu>

                        </td>                        
                      </tr>                      
                  </template>


                </v-data-table>
              </div>              
          </v-col>
        </v-row>
      </v-container>

    </v-main>
</template>

<script>

  import sidemenu from "./sidemenu.vue";

  export default {
    name: 'Home',

    props: ['message','userid'],

    components: {
      sidemenu    
    },

    data: () => ({    
        valid: false,
        search: '',        
        users: [],     
        partners: [], 
        openadduser: false,              
        adduser: {
          email: null,
          partner_id: null,
          name: null,
          role_id: 5
        },
        teamCategory: null, 
        categories: [],
        expanded: [],          
        isExpanded: Boolean,
        rules: {
          required: value => !!value || 'Required.',
          email: value => {
            //const pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          }
        }

    }),

    methods: {

      publish(id,bool) {          
          
          if(this.user.type === 'editor' || this.user.type === 'admin') {
            //const config = { headers: { 'Content-Type': 'multipart/form-data' } };
            const formData = new FormData();
            formData.set('id', id);
            formData.set('published', bool);
            let self = this;

            this.axios.post( this.$config.host+"/publish", formData)
            .then(function (result) {              
                if(result && result.data) {                              
                  let item = self.users.find((obj) => {
                    return obj.id === result.data.id;
                  });
                  bool = !bool;
                  item.published = bool;                

                } else {                                
                  console.log("no result.data");
                }              
            })
            .catch(function () {
                //this.output = error;
            });      
          } else {
            alert('You are not allowed to set the published status.')
          }
      },
      deleteUser( id ) {
        let self = this;
        const confirm = window.confirm('Are you sure?')    
        if (confirm) {
          this.axios.post( this.$config.host+"/user/delete/"+id )
          .then(function () {                            
              self.getUsers();
          })
          .catch(function (error) {
              self.output = error;
          });                
        }    
      },
      editUser(  ) {
        //let self = this;      
        /*       
        this.axios.post( this.$config.host+"/user/delete/"+id )
        .then(function () {                            
            self.getUsers();
        })
        .catch(function (error) {
            self.output = error;
        });                      
        */
      },      
      setPartner() {
        this.adduser.partner_id = this.user.partner_id;
      },                    
      saveUser() {

        //event.preventDefault();

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };

        const formData = new FormData();
        let self = this;       
        
        formData.set('email', this.adduser.email ? this.adduser.email : null);
        formData.set('partner_id', this.adduser.partner_id ? this.adduser.partner_id : null);
        formData.set('name', this.adduser.name ? this.adduser.name : null);
        formData.set('role_id', this.adduser.role_id ? this.adduser.role_id : null);
        //formData.set('id', item.id);
        
        this.axios.post( this.$config.host+"/user/add", formData, config)
        .then(function (result) {  
          
          if(result.status == 200) {                        
            self.getUsers();
            self.$refs.form.reset(); 
            self.openadduser = false;
          } else {
            console.log("something is wrong");
          }
          
          //var index = self.partners.findIndex(p => p.id == item.id);                     
          //self.partners[index].pdf = result.data.pdf;

          // Close upload window          
          //self.partners[index].edit = !self.partners[index].edit;                                                     

        })
        .catch(function (error) {
            console.log(error);
            self.output = error;
        });          

      },                      
      nodeValidate(item) {
        console.log("val: ");
        console.log(item);
        if(
          (this.user.type === 'admin')
          ) {            
          return true
        } else {
          return false
        }        
      },
      URLify(string){
        var urls = string.match(/(((ftp|https?):\/\/)[-\w@:%_+.~#?,&//=]+)/g);
        var urlLabel;
        if (urls) {
          urls.forEach(function (url) {
            urlLabel = url.replace(/^https?:\/\//, '');
            string = string.replace(url, '<a target="_blank" href="' + url + '">' + urlLabel + "</a>");
          });
        }
        return string.replace("(", "<br/>(");
      },      
      logClass() {
        console.log("teamCategory");
        console.log(this.teamCategory);
      },
      cover(url) {
          if (url !== ''){ // You can check any matching expression.
              try {
                  url = require('@/logo/' + url)
              } catch (e) {
                  url = require('@/logo/default.jpg'); // I used a default image. 
              }
          }
          else
              url = require('@/logo/default.jpg'); // Default image. 
          return url;
      },
      generateReport (content) {                            
          this.$refs.html2Pdf.generatePdfProfile(content)          
      },
      getUsers() { 
        let self = this;       
        this.axios.get( this.$config.host+"/userslist" ).then(response => (       
          self.users = response.data.filter((i) => {                          
            return i.published === true;
            //return this.user.type === 'admin';
            //return !this.teamCategory || (i.category_id === this.teamCategory);            
          })
        ));
      },   
      getPartners() { 
        let self = this;
        this.axios.get( this.$config.host+"/partners" ).then(response => (       
          self.partners = response.data,          
          self.adduser.partner_id = self.user.partner_id
        ));
      },               
      convertLineBreak(text) {        
        return text.replace(/\r\n/g, '<br />')        
      },      
      filterPartner (value, search) {                
        return value != null && 
          search != null &&
          ( 
            ( typeof value === 'string' && value.toString().toLowerCase().indexOf(search) !== -1 ) ||
            ( typeof value === 'object' && value.some( item => { return item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 } ) )
          )          
      }    
    },
    computed: {      
      user () {                     
        return this.$store.getters.userdata;
      },
      bgStyles() {
        return {
          "background-color": "#f0efef"          
        };
      },
      headers () {
        return [
          { 
            text: '', 
            value: 'active' 
          },                    
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
            width:'25%'
          },
          { 
            text: 'Institution', 
            value: 'partner',
            width:'25%'
          },          
          { 
            text: 'Teams', 
            value: 'teams',
            width:'25%'
          },          
          { 
            text: '', 
            value: 'data-table-expand' 
          },
          { 
            text: '', 
            value: '' 
          }          

        ]
      },      
      filteredUsers() {                
        return this.users.filter((i) => {
          if(this.user.type == 'admin') {            
            return !this.teamCategory || (i.category_id === this.teamCategory);
          } else if (this.user.type == 'editor') {            
            return i.partner_id === this.user.partner_id;
          }
        })
      }
    },
    mounted() {    
      setTimeout(() => {
          this.getUsers();
          this.getPartners();    
      }, 200);
    }

  }
</script>

<style scoped>

.fade-enter-active,
.fade-leave-active {
  transition: opacity .8s ease;
}

.fade-enter,
.fade-leave-to {  
  transition: opacity .2s ease;  
  opacity: 0;
}

.token {
  display:inline-block;
  padding:0 4px;  
  font-size:0.8rem;
  background-color: lightblue;
}

.item {
    margin:60px 80px;
}
.item h2 {
    font-size:2.2rem;
}
.item p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: lightcyan;
}
.item table {
  width: 100%;
}
.logo img {
  border:1px solid #eee;  
  max-width: 100%;
}

</style>

<style>

@media only screen and (max-width: 600px) {

  .researchtable > .v-data-table__wrapper table tr {
    display:flex;
  }
  .researchtable > .v-data-table__wrapper table tr td {
    font-size:0.7rem !important;
    padding:2px 4px !important;
    height:auto !important;
  }
  .researchtable > .v-data-table__wrapper table tr td:nth-child(1) {
    width:20%;
    background-color:#f9f9f9;
  }
  .researchtable > .v-data-table__wrapper table tr td:nth-child(2) {
    width:40%;
  }
  .researchtable > .v-data-table__wrapper table tr td:nth-child(3) { 
    width:30%;
    background-color:#f9f9f9;
  }
  .researchtable > .v-data-table__wrapper table tr td:nth-child(4) {
    width:10%;
  }

}

.researchtable > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;  
}
.researchtable > .v-data-table__wrapper tbody tr.v-data-table__expanded__content > td {
  border:0;
}
.researchtable > .v-data-table__wrapper > table > thead > tr > th {
  white-space: nowrap;
}
.researchtable > .v-data-table__wrapper > table .expandedinfo {
  font-size:.88rem;
}

</style>