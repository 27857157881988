<template>
  <v-app>
    <v-app-bar
      app
      color="white"      
      >
      <div class="d-flex align-center">     </div>

      <v-toolbar-title>
        <router-link :to="$config.path" class="homelink">
          <img :src="cover('bhs_brandenburg_health_sciences.png')" style="width:auto;height:60px" class="pr-2 mt-3" />          
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <router-link :to="$config.path" class="mr-6">
        <v-icon
        color="#999"
        size="22"
        >mdi-home</v-icon>
      </router-link>      

      <router-link :to="$config.path+'formular'" class="mr-6" v-if="user && user.name">
        <v-tooltip max-width="340" left>
          <template v-slot:activator="{ on, attrs }">                  
          <v-icon
            v-bind="attrs"
            v-on="on"
            style="display:inline-block"
            color="#999"
            size="22"
            >mdi-text-box-plus</v-icon>
          </template>
          <span>Add data set</span>
        </v-tooltip>                     
      </router-link>            

      <router-link :to="$config.path+'list/'+user.id" class="mr-10" v-if="user && user.name">
        <v-tooltip max-width="340" left>
          <template v-slot:activator="{ on, attrs }">                  
          <v-icon
            v-bind="attrs"
            v-on="on"
            style="display:inline-block"
            color="#999"
            size="22"
            >mdi-playlist-edit</v-icon>
          </template>
          <span>Edit data sets</span>
        </v-tooltip>                     
      </router-link>                

<!--
      <router-link :to="$config.path+'users'" class="mr-10" v-if="user && user.name && user.type == 'admin'">
        <v-tooltip max-width="340" left>
          <template v-slot:activator="{ on, attrs }">                  
          <v-icon
            v-bind="attrs"
            v-on="on"
            style="display:inline-block"
            color="#999"
            size="22"
            >mdi-account-group</v-icon>
          </template>
          <span>Administrate users</span>
        </v-tooltip>                     
      </router-link>                
-->          

      <router-link :to="$config.path+'profile'" class="mt-1 mr-8 px-4 rounded userdisplay" v-if="user && user.name">
        <div>
          <span>{{ user.name }}</span>
        </div>
      </router-link>            

      <router-link :to="$config.path+'login'" class="mr-6">
        <v-icon
        color="#999"
        size="22"
        >mdi-login</v-icon>
      </router-link>

      <div v-if="$route.path == '/'+$config.path">
      </div>
<!--
      <img 
        src="./images/logo_thwi.png"
        height="38px"
        width="auto"
        class="mr-8"
        />
-->

    </v-app-bar>

    <router-view :controls="controls" :key="$route.path" :template="template"></router-view> 

    <v-footer      
      class="innoblue"
      padless
      >
      <v-container>
      <v-row
        justify="center"
        no-gutters        
      >
        <v-col
          class="light lighten-1 py-4 text-center white--text"
          md="12"
        >
          <v-btn
            v-for="link in links"
            :key="link.name"
            color="white"
            text
            rounded
            class="my-2"
            :to="$config.path+''+link.url"
          >
            {{ link.name }}
          </v-btn>
        </v-col>
      </v-row>
      </v-container>

      <v-container>
      <v-row>
        <v-col
          class="light lighten-1 py-4 pt-8 text-center white--text"
          md="12"
        >                                                 
          Jointly developed by the Innovation Hub 13 and the Faculty of Health Sciences Brandenburg
        </v-col>

        <v-col
          class="light lighten-1 py-4 pt-2 text-center white--text logo"        
          md="12"                    
        >   
          <a href="https://www.innohub13.de" class="link" target="_blank">
          <img :src="$config.path+'files/ih-logo_w.png'" height="100px" title="" alt="" />
          </a>
          <a href="https://www.fgw-brandenburg.de/" class="link" target="_blank">
          <img :src="$config.path+'files/fgw-logo.png'" height="100px" title="" alt="" />
          </a>
        </v-col>
<!--
        <v-col
          class="light lighten-1 py-4 pt-2 text-center white--text"
          md="12"
        >                         
          → <a href="https://www.innohub13.de" class="link" target="_blank">www.innohub13.de</a>
        </v-col>
-->
        <v-col
          class="light lighten-1 py-4 pb-8 pt-8 text-center white--text"
          cols="12"
        >
        <a href="https://innohub13.de/impressum/" class="link" target="_blank">Impressum</a><br>
        {{ new Date().getFullYear() }} — TH Wildau
        </v-col>
      </v-row>
      </v-container>
    </v-footer>

  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
    
  },

  data: () => ({        
      template: null,      
      controls: {
        visible: true,
      },          
      links: [
        {
          name: 'Home',
          url: ''
        },
        /*
        {
          name: 'About Us',          
          url: 'about'
        },
        */
        {
          name: 'Service & Contact',
          url: 'contact'
        }
      ]
  }),
  methods: {
    getUserData: function() {
      let self = this;
      this.axios.get( this.$config.host+"/user")
            .then((response) => {                                    
                self.$store.commit('setUser', response.data);                                    
            })
            .catch(() => {
                self.$store.commit('clearUser')
                self.autherror = true;
                //this.$router.push("/"+self.$home+"login")
            })
    },
    cover(url) {
        if (url !== ''){ // You can check any matching expression.
            try {
                url = require('@/logo/' + url)
            } catch (e) {
                url = require('@/logo/default.jpg'); // I used a default image. 
            }
        }
        else
            url = require('@/logo/default.jpg'); // Default image. 
        return url;
    },    
  },
  computed:{
      user () {                        
        return this.$store.getters.userdata
      },
      condenseHeader () {
        return this.$route.fullPath;
      }
  },
  watch: {
      user: function () {          
          //this.node = values;          
          //console.log("watch user "); 
          //console.log(values);
      },        
      '$route.path': function () {                
        this.getUserData();
      }       
  },
  mounted() {
      this.getUserData();      
  }

};
</script>
<style>
  @import './assets/styles/variables.css';
</style>

<style scoped>
.homelink {
  color: #666;
  text-decoration: none;
}
.router-link-active,
.v-toolbar a {
  text-decoration: none;
}
</style>
<style>
  body {
    font-family: "Heebo", "Helvetica", "Arial", sans-serif;
  }
  a,
  .v-application a {
    color: #4682B4;
    text-decoration: none;
  }
  a.link {
    color: #ffffff !important;
  }
  .v-application div > a,
  .v-application p > a {
    color: #666;
  }
  .background {
    background-color: var(--bg-color-box);    
  }
  .innoblue {
    background-color: #005ca9 !important;
  }
  .userdisplay {
    background-color: rgba(110, 180, 214, 0.3);
    text-decoration: none;
  }
  .title {
    padding: var(--title-padding);    
  }
  h1.ratitle {
    font-size:1.8rem;
    line-height: 2.6rem;
    font-weight:600;
    color:#4682B4;
    margin-bottom:.6rem;    
  }
  h1.mytitle {
    font-size:1.4rem;
    color:#444444;
  }
  h2.mytitle {
    font-size:1.1rem;
    color:#444444;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom:10px;
  }
  .logo img {
    max-width:100%;
  }
  .logo > a {
    display:inline-block;
    margin:0 0px;
  }
</style>
