<template>  
    <v-navigation-drawer      
      height="auto"      
      permanent
      left
      class="sidemenu"
    >
    <!--
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <img src="https://randomuser.me/api/portraits/women/81.jpg">
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>Max Mustermann</v-list-item-title>
            <v-list-item-subtitle>Logged In</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
    
      <v-divider></v-divider>
      -->

      <v-list 
        dense
        class="menulist"        
        >        
        <v-list-item
          link          
          :to="$config.path+'list/'+user.id"
          color="blue"
          v-if="$store.state.access.read['guestmenu']"
          >
          <v-list-item-content>
            <v-list-item-title>              
              <v-icon class="mr-6">mdi-account</v-icon>Content
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link                             
          :to="$config.path+'users'" 
          color="blue"
          v-if="$store.state.access.read['editormenu']"
          >                  
            <v-list-item-content>
              <v-list-item-title>
                <v-icon class="mr-6">mdi-account</v-icon>Users
              </v-list-item-title>
            </v-list-item-content>        
        </v-list-item>

        <v-list-item
          link          
          :to="$config.path+'partners'"
          color="blue"
          v-if="$store.state.access.read['editormenu']"
          >
          <v-list-item-content>
            <v-list-item-title>              
              <v-icon class="mr-6">mdi-account</v-icon>Fact Sheets
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>        
        
      </v-list>
    </v-navigation-drawer>  
</template>

<script>
  export default {
    name: 'Sidemenu',

    data: () => ({
      //loading: false,
      //selection: 1,
    }),
    methods: {

    }, 
    computed:{
      user () {                        
        return this.$store.getters.userdata
      },

    },
    watch: {
        user: function () {          
            //this.node = values;          
            //console.log("watch user "); 
            //console.log(values);
        }
    },       
  }
</script>
<style>
.sidemenu .v-navigation-drawer__border {
  display:none;
}
</style>
<style scoped>  
.sidemenu {  
  position: -webkit-sticky; /* for safari */
  position:sticky;
  top:100px !important;
}
.router-link-active {
  background-color: #f2f2f2;
}
</style>