<template>
    <v-main>

      <v-container v-if="message">
        {{ $router }}
      </v-container>       
      {{ $route.params.message }}     

      <!-- Table data public -->
      <v-container
        class="admincontainer"
        v-if="user && user.id && (user.type == 'admin' || user.type == 'editor')"              
        >
        <v-row
          v-if="user.name"
          class="mb-8"          
          >
          <v-col
            cols="2"
            md="2"             
            >                      
            <sidemenu></sidemenu>       
          </v-col>

          <v-col
            cols="10"
            md="10" 
            >                 
              <div class="ml-4 mt-8 mb-4">         
                <h2>Fact sheets</h2>
              </div>                       
              <div>         
                <v-data-table                    
                  :headers="headers"
                  :items="filteredPartners"
                  :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                  }"
                  :items-per-page="30"                  
                  :search="search"                                                               
                  item-key="id"
                  class="elevation-1 partnertable"
                  >
                  <template v-slot:item="{ item }">
                      <tr>        
                        <td>
                          <div v-if="item.src">                            
                            <img :src="cover(item.src)" height="40px" alt="" />                            
                          </div>                          
                        </td>
                        <td>
                          {{ item.name }}
                          <!--
                          <router-link :to="$config.path+'user/' + item.id" text small>
                            {{ item.name }}
                          </router-link>                                                    
                          -->
                        </td>
                        <td>
                          <div v-if="item.pdf">
                            <a :href="$config.path+'files/pdf/'+item.pdf" target="_blank" style="display:inline-block">
                            {{ item.pdf }}
                            </a>    
                            <!--
                            <v-icon                
                              size="14"
                              color="gray"
                              class="px-0 py-0 mx-2 delete"
                              @click="deletePdf(item)"                                                        
                              style="display:inline-block"
                              v-if="item.edit"
                              >
                              mdi-trash-can-outline
                            </v-icon>                                                                   
                            -->
                          </div>                                                    
                          <div v-if="item.edit">
                            <v-file-input
                              v-model="pdf[item.id]"
                              :rules="rules"
                              accept="application/pdf"
                              placeholder="Select a file"
                              prepend-icon="mdi-file-pdf-box"
                              style="font-size:.76rem"                                                            
                            ></v-file-input>                     
                          </div>                          
                        </td>
                        <td >
                          <div v-if="item.class && user.type == 'admin'">
                            {{item.class }}
                          </div>                       
                        </td>                        
                        <td>         
                          <div v-if="!item.edit">
                          <v-icon                
                            size="14"
                            color="gray"
                            class="px-0 py-0 mx-1"
                            @click="edit(item)"                                                        
                            >
                            mdi-pencil-outline                           
                          </v-icon>           
                        </div>                 
                        <div v-else>
                          <v-icon                
                            size="14"
                            color="gray"
                            class="px-0 py-0 mx-1"
                            @click="edit(item)"                                                                                    
                            >
                            mdi-close
                          </v-icon>                                                        
                          <v-icon                
                            size="14"
                            color="gray"
                            class="px-0 py-0 mx-1"                            
                            @click="savePartner(item,$event)"                                                                                    
                            >
                            mdi-check
                          </v-icon>                                                        
                        </div>
                        </td>
                      </tr>                      
                  </template>
                </v-data-table>
              </div>              
          </v-col>
        </v-row>
      </v-container>

    </v-main>
</template>

<script>

  import sidemenu from "./sidemenu.vue";

  export default {
    name: 'Partners',

    props: ['message','userid'],

    components: {
      sidemenu    
    },

    data: () => ({    
        search: '',        
        partners: [],
        pdf: [],
        class:[],
        expanded: [],         
        isExpanded: Boolean,
        rules: [
          value => !value || value.size < 2000000 || 'Fact Sheet size should be less than 2 MB!',
        ],
        classRules: [
          //v => !!v || 'Name is required',
          v => (v && v.length <= 30) || 'Name must be less than 30 characters'
        ],
    }),

    methods: {

      cover(url) {
          if (url !== ''){ // You can check any matching expression.
              try {
                  url = require('@/logo/' + url)
              } catch (e) {
                  url = require('@/logo/default.jpg'); // I used a default image. 
              }
          }
          else
              url = require('@/logo/default.jpg'); // Default image. 
          return url;
      },

      edit(item) {                
        var index = this.partners.findIndex(p => p.id == item.id);                                        
        this.partners[index].edit = !this.partners[index].edit;                        
      },

      deletePdf(item) {

        console.log("delete item");
        console.log(item);

      },
      getPartners() { 
        let self = this;
        this.axios.get( this.$config.host+"/partners" ).then(response => (       
          self.partners = response.data
        ));
      },      
      /*
      convertLineBreak(text) {        
        return text.replace(/\r\n/g, '<br />')        
      },      
      */
      filterPartner (value, search) {                
        return value != null && 
          search != null &&
          ( 
            ( typeof value === 'string' && value.toString().toLowerCase().indexOf(search) !== -1 ) ||
            ( typeof value === 'object' && value.some( item => { return item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 } ) )
          )          
      },
      savePartner(item,event) {

          event.preventDefault();
        
          const config = { headers: { 'Content-Type': 'multipart/form-data' } };
          const formData = new FormData();
          let self = this;        

          //formData.set('class', this.partner[itemId].class ? this.partner[itemId].class : null);
          formData.set('id', item.id);
          formData.append('pdf', this.pdf[item.id]);        
          
          this.axios.post( this.$config.host+"/partner", formData, config)
          .then(function (result) {              

            var index = self.partners.findIndex(p => p.id == item.id);                     
            self.partners[index].pdf = result.data.pdf;

            // Close upload window          
            self.partners[index].edit = !self.partners[index].edit;                                           

          })
          .catch(function (error) {
              console.log(error);
              self.output = error;
          });          

      },                
    },
    
    computed: {      

      user () {       
        return this.$store.getters.userdata
      },
      bgStyles() {
        return {
          "background-color": "#f0efef"          
        };
      },
      headers () {
        return [
          { 
            text: 'Image', 
            align: 'start',
            value: 'src',
            width:'12%'
          },                                        
          {
            text: 'Name',            
            sortable: true,
            value: 'name',
            width:'45%'
          },
          { 
            text: 'Fact Sheet', 
            value: 'pdf',
            width:'25%'
          },
          { 
            text: '', 
            value: 'class',
            width:'12%'
          },                                    
          { 
            text: '', 
            value: 'edit',
            width:'6%'
          }
        ]
      },      
      filteredPartners() {
        return this.partners.filter((i) => {
          if(this.user.type == 'admin') {
            return true;
          } else if(this.user.type == 'editor') {
            return i.id == this.user.partner_id;          
          }
        })
      }
    },
    mounted() {    
      this.getPartners()      
    }

  }
</script>

<style scoped>

.token {
  display:inline-block;
  padding:0 4px;  
  font-size:0.8rem;
  background-color: lightblue;
}

.item {
    margin:60px 80px;
}
.item h2 {
    font-size:2.2rem;
}
.item p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: lightcyan;
}
.item table {
  width: 100%;
}
.logo img {
  border:1px solid #eee;  
  max-width: 100%;
}

</style>

<style>

.partnertable a {
  display:block;
  padding: 12px 4px;
}

@media only screen and (max-width: 600px) {

  .researchtable > .v-data-table__wrapper table tr {
    display:flex;
  }
  .researchtable > .v-data-table__wrapper table tr td {
    font-size:0.7rem !important;
    padding:2px 4px !important;
    height:auto !important;
  }
  .researchtable > .v-data-table__wrapper table tr td:nth-child(1) {   
    background-color:#f9f9f9;
  }

}

.researchtable > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;  
}
.researchtable > .v-data-table__wrapper tbody tr.v-data-table__expanded__content > td {
  border:0;
}
.researchtable > .v-data-table__wrapper > table > thead > tr > th {
  white-space: nowrap;
}
.researchtable > .v-data-table__wrapper > table .expandedinfo {
  font-size:.88rem;
}

</style>