<template>
    <v-main>

      <v-dialog
        v-model="videodialog"
        width="1060"
        content-class="videodialog"
        >
        <v-card>
          <v-card-actions
            class="py-1 px-1"
            >
            <v-spacer></v-spacer>
            <v-btn
              small                        
              text
              @click="videodialog = !videodialog; stopvideo()"
            >
              <v-icon            
                dense
                >
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-actions>
          <video id="videoPlayer" width="1060" height="600" controls>
            <source src="https://wp2.innohub13.de/wp-content/uploads/Transferangebote-FGW-final-XL.mp4" type="video/mp4">          
          Your browser does not support the video tag.
          </video> 
        </v-card>
      </v-dialog>      

      <!-- Title -->
      <div        
        v-if="!userid"
        class="mb-6 pt-4 pb-4"
        :style="bgStyles"
        >      
        <v-container>
            <v-row>
              <v-col
                cols="12"
                md="10"
                offset-md="1"
              >                          
              <h1 class="ratitle">                              
              Brandenburg Health Sciences: A Research Atlas
              </h1>          
              </v-col>                                      
            </v-row>

            <v-row>

              <v-col
                cols="12"
                md="5"
                sm="6"
                xs="12"
                offset-md="1"
                class="pr-8"
              >                          
              <p>                          
              Brandenburg has a strong and diverse expertise in the health sciences. With your support, we will develop a research atlas in which existing competences and infrastructures are brought together centrally and can be accessed quickly and easily. Our goal is to promote networking, scientific exchange and the establishment of joint collaborations in the state of Brandenburg across institutions.
              </p>
              </v-col>

              <v-col
                cols="12"
                md="3" 
                sm="6"
                xs="12"               
                class="pl-4"
              >
                <div style="padding:4px;">
                <div class="videopreview">                  
                  <img @click="videodialog = true" :src="imgcover('imagefilm-screenshot.jpg')" />
                </div>
                <p style="font-size:.86rem">Transfer offers in the health sciences in Brandenburg (video in German)</p>
                </div>                                     
              </v-col>
              
              <v-col
                cols="12"
                md="3" 
                sm="6"
                xs="12"               
                class="pl-4"
              >                          
              <p><b>Brandenburg Health Science Talks</b></p>
                <v-btn class="mr-4 mb-4 homebutton"
                  small
                  depressed                  
                  color="#113759"                                                    
                  href="/files/pdf/BHS-Talks_2024_neu.pdf" target="_blank"
                  >
                  Programme overview
                </v-btn>
                <v-btn class="mr-4 mb-4 homebutton"
                  small
                  depressed                  
                  color="#113759"            
                  href="/files/pdf/BHS-Talks-hereon_2024-06-19.pdf" target="_blank"
                  >
                  Next talk
                </v-btn>
              </v-col>
                                                    
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="10"
                offset-md="1"
              >                          
              <p>
              Below you can find all participating institutions (click on the logos to get more information):
              </p>              
              </v-col>                                      
            </v-row>

            <!-- Logos and map -->
            <v-row>
              <v-col
                cols="12"
                md="5"
                sm="6"
                xs="12"
                offset-md="1"
                >              
                <v-row>
                  <v-col
                    cols="6"
                    sm="4"
                    xm="6"
                    class="logo"
                    v-for="(logo, l) in logos"
                    :key="l"                  
                  >                                    
                    <a :class="setClass(logo.class)" :href="getHref(logo.pdf)" :title="logo.name" target="_blank"><img :src="cover(logo.src)" @mouseover="showMap(logo.class, logo.name)" @mouseout="hideMap()" /></a>
                  </v-col>
                </v-row>
              </v-col> 

              <v-col
                cols="12"
                sm="6"
                md="5"
                xs="12"                
                >        
                <div class="wrap">
                  <div id="mapinfo" ref="mapinfo" v-html="infoContent"></div>
                  <brandenburgmap
                    ref="map"         
                    @mouseOnMap="getMouseEvent"     
                    @showMapInfo="showMap"
                    @hideMapInfo="hideMap"
                    ></brandenburgmap>
                    <!--
                    <img :src="cover('brandenburg_3.png')" style="max-width:100%;height:auto" class="pr-2" />                
                    -->
                </div>      

              </v-col>              

            </v-row>

        </v-container>
      </div>

      <v-container v-if="message">
        {{ $router }}
      </v-container>       
      {{ $route.params.message }}     

      <!-- Submit button -->
      <v-container
        v-if="!userid"
        >
          <v-row class="text-center" v-if="user.name">

            <v-col 
              cols="12"
              md="12"
              >
              <router-link :to="$config.path+'formular'">
              <v-btn               
                color="#778899"
                class="ma-2 mb-8 white--text"                    
                >
                Submit data set
                <v-icon
                  right
                  dark
                >
                  mdi-upload
                </v-icon>
              </v-btn>
              </router-link>
            </v-col>      

          </v-row>
      </v-container>      

      <!-- Search -->
      <v-container
        v-if="!userid"
        >
        <v-row          
          class="mb-8"
          >
          <v-col
            cols="4"
            md="3" 
            offset-md="1"             
            >     
            <div>         
              <v-select                 
                :items="categories"
                v-model="teamCategory"       
                item-value="id"
                placeholder="Classification"
                item-text="name"           
                clearable 
                @change="logClass"      
              ></v-select>      
              </div>            
          </v-col>   
          <v-col
            cols="8"
            md="7"            
            >         
            <div>         
              <v-text-field                  
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                clearable
                class="mb-2 mx-2 px-2"
                color="green"
              ></v-text-field>
            </div>
          </v-col>
        </v-row>                   
      </v-container>

      <!-- Table data public -->
      <v-container
        v-if="!userid"
        >
        <v-row          
          class="mb-8"          
          >
          <v-col
            cols="12"
            md="12"             
            >                            
              <div>         
                <v-data-table                    
                  :headers="headers"
                  :items="filteredTeams"
                  :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50],
                    'items-per-page-text':'Profiles per page',
                  }"
                  :items-per-page="30"
                  :single-expand="true"
                  :search="search"           
                  :custom-filter="filterPartner"       
                  show-expand                                                                                          
                  :expanded.sync="expanded"
                  item-key="id"
                  class="elevation-1 researchtable"
                  >
                  <template v-slot:item="{ item, expand, isExpanded }">
                      <tr>                        
                        <td>
                          <router-link :to="$config.path+'node/' + item.id" text small>
                            {{ item.name }}
                          </router-link>
                        </td>
                        <td>
                          <div v-if="item['category']">
                            {{item['category'][0].name }}
                          </div>
                          </td>
                        <td class="d-block d-sm-table-cell" @click="expand(!isExpanded)">
                          
                          <img :src="cover(item['partner'][0].logo_src)" style="float:left;width:20%;" />
                          
                          <div style="float:right;width:77%;padding:6px 0;">
                          {{item['partner'][0].name }}                          
                          </div>
                        </td>
                        <td>
                          <div
                            @click="expand(!isExpanded)"
                            >
                            <v-icon small v-if="isExpanded">mdi-chevron-up</v-icon>
                            <v-icon small v-else>mdi-chevron-down</v-icon>
                            </div>
                          </td>
                      </tr>                      
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" style="background-color:#f8f8f8;" class="expandedinfo">
                    <!--
                      <div style="display:inline-block;width:38%;padding:20px;white-space: pre;">
                        <div v-html="item.contact"></div>                         
                      </div>                        
                      -->
                      <div style="display:inline-block;width:87%;padding:20px;vertical-align:top">                        
                        <span>RESEARCH FOCUS & METHODOLOGICAL EXPERTISE</span>
                        <ul>
                        <li
                          v-for="(foc, i) in item.focus"
                          :key="i"                  
                          >{{ foc.name }}</li>                                    
                          </ul>
                      </div>                      
                      <div align="right" style="display:inline-block;width:12%;vertical-align:top;padding-top:10px">
                        <router-link 
                          v-if="user.name && nodeValidate(item)"
                          :to="$config.path+'node/' + item.id + '/edit'" text small
                          >
                          <v-icon                
                            size="18"
                            color="red"
                            class="px-0 py-0 mr-0"
                            >
                            mdi-pencil-outline
                          </v-icon>    
                        </router-link>              
                        <v-icon
                          @click="generateReport(item)" 
                          size="26"
                          color="green"                          
                          class="px-1 py-1"
                          >
                          mdi-file-pdf-box
                        </v-icon>              
                      </div>
                    </td>
                  </template>                    

                </v-data-table>
              </div>              
          </v-col>
        </v-row>
      </v-container>

      <!-- Table data by profile -->
      <v-container
        class="admincontainer"
        v-if="(userid && user.id)"
        >         
        <v-row        
          class="mb-8"          
          >                  
          <v-col
            cols="2"
            md="2"             
            >                      
            <sidemenu></sidemenu>       
          </v-col>

          <v-col
            cols="10"
            md="10" 
            >                      
              <div class="ml-4 mt-8 mb-4">         
                <h2>Edit data</h2>                     
              </div>                       
              
              <div>         
                <v-data-table                    
                  :headers="headersProfile"                  
                  :sort-by="['name']"
                  :sort-desc="[false]"
                  :items="filteredTeams"
                  :footer-props="{                    
                    'items-per-page-options': [10, 20, 30, 40, 50]                    
                  }"
                  :items-per-page="30"
                  :single-expand="true"
                  :search="search"           
                  :custom-filter="filterPartner"       
                  show-expand
                  :expanded.sync="expanded"
                  item-key="id"
                  class="elevation-1 researchtable"
                  >
                  <template v-slot:item="{ item, expand, isExpanded }">
                      <tr>           
                        <td style="text-align: center;">
                          <v-icon                             
                            color="green"
                            v-if="item.published"
                            @click="publish(item.id, item.published, item.submitted)"
                            >mdi-check</v-icon>
                          <v-icon                             
                            v-else
                            small
                            color="grey"
                            @click="publish(item.id, item.published,  item.submitted)"
                            >mdi-timer-sand</v-icon>                            
                        </td>             
                        <td>
                          <router-link :to="$config.path+'node/' + item.id" text small>
                            {{ item.name }}
                          </router-link>                                                    
                        </td>
                        <td>
                          <div v-if="item['category']">
                            {{item['category'][0].name }}
                          </div>
                          </td>
                        <td class="d-block d-sm-table-cell" @click="expand(!isExpanded)">
                          <span v-if="item['partner']">
                            {{item['partner'][0].name }}                          
                          </span>                          
                        </td>
                        <td>
                          <span v-if="item['ownerdata']">
                            {{item['ownerdata'][0].name }}                          
                          </span>                          
                        </td>                        
                        <td style="text-align: center;" class="px-0 mx-0 greylight">
                          <v-icon                             
                            color="green"
                            v-if="item.submitted"                            
                            >mdi-check</v-icon>
                          <v-icon                             
                            v-else
                            small
                            color="grey"
                            >mdi-timer-sand</v-icon>                            
                        </td>                                                             
                        <td>
                          <div
                            @click="expand(!isExpanded)"
                            >
                            <v-icon small v-if="isExpanded">mdi-chevron-up</v-icon>
                            <v-icon small v-else>mdi-chevron-down</v-icon>
                            </div>
                          </td>
                      </tr>                      
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" style="background-color:#f8f8f8;">
                    <!--
                      <div style="display:inline-block;width:38%;padding:20px;white-space: pre;">
                        <div v-html="item.contact"></div>                         
                      </div>                                                           
                      -->
                      <div style="display:inline-block;width:87%;padding:20px;vertical-align:top">                        
                        <span>RESEARCH FOCUS & METHODOLOGICAL EXPERTISE</span>
                        <ul>
                        <li
                          v-for="(foc, i) in item.focus"
                          :key="i"                  
                          >{{ foc.name }}</li>                                    
                          </ul>
                      </div>                      
                      <div align="right" style="display:inline-block;width:12%;vertical-align:top;padding-top:10px">
                        <router-link 
                          v-if="user.name && nodeValidate(item)"
                          :to="$config.path+'node/' + item.id + '/edit'" text small
                          >
                          <v-icon                
                            size="22"
                            color="red"
                            class="px-0 py-0 mr-0"
                            >
                            mdi-pencil-outline
                          </v-icon>    
                        </router-link>              
                        <v-icon
                          @click="generateReport(item)" 
                          size="26"
                          color="green"                          
                          class="px-1 py-1"
                          >
                          mdi-file-pdf-box
                        </v-icon>              
                      </div>
                    </td>
                  </template>                    

                </v-data-table>
              </div>              
          </v-col>

        </v-row>
      </v-container>

      <!-- Submit button -->
      <v-container
        v-if="!userid"
        >
          <v-row class="text-center" v-if="user.name">

            <v-col 
              cols="12"
              md="12"
              >
              <router-link :to="$config.path+'formular'">
              <v-btn               
                color="#778899"
                class="ma-2 mb-8 white--text"                    
                >
                Submit data set
                <v-icon
                  right
                  dark
                >
                  mdi-upload
                </v-icon>
              </v-btn>
              </router-link>
            </v-col>      

          </v-row>
      </v-container>

      <!-- Generate PDF -->
      <v-container>
        <v-row
          class="mb-2"
          >
          <v-col
            cols="12"
            md="8" 
            offset-md="2"             
            >
            <pdfprofile               
              ref="html2Pdf"              
              ></pdfprofile>
          </v-col>
        </v-row>
      </v-container>


    </v-main>
</template>

<script>

  import sidemenu from "./sidemenu.vue";
  import pdfprofile from "./pdfprofile.vue";
  import brandenburgmap from "./brandenburgmap.vue";

  export default {
    name: 'Home',
    props: ['message','userid'],

    components: {      
      pdfprofile,
      brandenburgmap,
      sidemenu
    },

    data: () => ({    
        search: '',
        teams: [],    
        teamsAdmin: [],    
        teamCategory: null, 
        categories: [],
        expanded: [],    
        infoContent: '',      
        isExpanded: Boolean,
        logos: [],
        videodialog: false
    }),

    methods: {
      stopvideo () {
        let videoPlayer = document.querySelector('#videoPlayer')
        console.log(videoPlayer)
        videoPlayer.pause()
      },
      customSort(items, index, isDesc) {
        items.sort((a, b) => {
          if(Array.isArray(a[index])) {        
            if (isDesc != "false") {
              return a[index][0]['name'] < b[index][0]['name'] ? -1 : 1
            } else {
              return b[index][0]['name'] < a[index][0]['name'] ? -1 : 1
            }
          } else {
            if (isDesc != "false") {
              return a[index] < b[index] ? -1 : 1              
            } else {
              return b[index] < a[index] ? -1 : 1              
            }
          }        
        })
        return items
      },
      getMouseEvent(cla) {
                
        if(cla == 'out') {          
          document.querySelectorAll('.logo a').forEach(function(el) {            
            el.classList.remove('active');
          });        

        } else if(cla) {
          let elem = document.querySelector('.'+cla);        
          elem.classList.add('active');
        }      
      },
      setClass(classes) {
        if( Array.isArray(classes) ) {
          var c = '';          
          classes.forEach(function (cl) {
              c += ' '+cl;
          })
          return c;
        } else {          
          return classes;
        }
      },
      offset(el) {
        let bodyRect = el.parentElement.closest('svg').getBoundingClientRect();
        var elRect = el.getBoundingClientRect();
        var topoffset   = elRect.top - bodyRect.top;
        var leftoffset   = elRect.left - bodyRect.left;
        return { top: topoffset, left: leftoffset }
      },
      getHref(pdf) {
        if(pdf == null) {         
            return "";
        } else if(pdf.includes("https")) {      
            return pdf;
        } else {
          return '/files/pdf/'+pdf;
        }          
      },
      showMap(logoclass, title) {    

        let thisTitle = title;

        if(!title) {          
          let item = this.logos.find((obj) => {
            return ( obj.class && (obj.class && obj.class === logoclass || obj.class.includes(logoclass)) )
          });          
          if(item) {
            thisTitle = item.name;
          }          
        }

        let self = this;
        let thisClasses = '';

        if(Array.isArray(logoclass)) {            

            console.log("array");

            logoclass.forEach(function callback(item, index) {
                
              let elem = document.querySelector('#bb_layer .'+item);
              elem.style.fill = "#4682B4";

              if(index == 0) {
                var divOffset = self.offset(elem);
                self.showInfo(divOffset, thisTitle);              
              }
            });          

        } else {

          console.log("no array");

          thisClasses = '#bb_layer .'+logoclass;
          let elem = document.querySelector(thisClasses);
          if(elem) {
            elem.style.fill = "#4682B4";               
            var divOffset = this.offset(elem);                        
            this.showInfo(divOffset, thisTitle);
          }
        }
              
      },
      showInfo(offset, title) {      
        this.$refs.mapinfo.style.top = (offset.top + 6) + "px";
        this.$refs.mapinfo.style.left = (offset.left +8) + "px";
        this.$refs.mapinfo.style.border = "1px solid #eee";
        this.$refs.mapinfo.classList.add('visible');
        this.infoContent = title;
      },
      hideMap() {                
        document.querySelectorAll(".cls-1").forEach(function(el) {
          el.style.fill = "#666";
        });        
        document.querySelector("#mapinfo").classList.remove('visible');
      },
      publish(id,bool,submitted) {          

          if(!submitted) {
            alert("Profile is not submitted yet.");
            return;
          } else {
          
            if(this.user.type === 'editor' || this.user.type === 'admin') {
              //const config = { headers: { 'Content-Type': 'multipart/form-data' } };
              const formData = new FormData();
              formData.set('id', id);
              formData.set('published', bool);
              let self = this;

              this.axios.post( this.$config.host+"/publish", formData)
              .then(function (result) {              
                  if(result && result.data) {                              
                    let item = self.teams.find((obj) => {
                      return obj.id === result.data.id;
                    });
                    bool = !bool;
                    item.published = bool;                

                  } else {                                
                    console.log("no result.data");
                  }              
              })
              .catch(function () {
                  //this.output = error;
              });      
            } else {
              alert('You are not allowed to set the published status.')
            }

          }
      },
      nodeValidate(item) {
        if(
          //(!this.user.team_id && !this.node.id) || (user.id == node.owner)
          (this.user.type === 'editor' && item.partner_id === this.user.partner_id) ||
          (this.user.id === item.owner) ||
          (!this.user.team_id && !item.id && this.user.type === 'editor') ||
          (!this.user.team_id && !item.id) ||
          (this.user.type === 'admin')
          ) {            
          return true
        } else {
          return false
        }        
      },
      URLify(string){
        var urls = string.match(/(((ftp|https?):\/\/)[-\w@:%_+.~#?,&//=]+)/g);
        var urlLabel;
        if (urls) {
          urls.forEach(function (url) {
            urlLabel = url.replace(/^https?:\/\//, '');
            string = string.replace(url, '<a target="_blank" href="' + url + '">' + urlLabel + "</a>");
          });
        }
        return string.replace("(", "<br/>(");
      },      
      logClass() {
        console.log("teamCategory");
        console.log(this.teamCategory);
      },
      imgcover(url) {
          if (url !== ''){ // You can check any matching expression.
              try {
                  url = require('@/images/' + url)
              } catch (e) {
                  console.log(e);
                  // url = require('@/logo/default.jpg'); // I used a default image. 
              }
          }
          else
              url = ''; // Default image. 
          return url;
      },
      cover(url) {
          if (url !== ''){ // You can check any matching expression.
              try {
                  url = require('@/logo/' + url)
              } catch (e) {
                  url = require('@/logo/default.jpg'); // I used a default image. 
              }
          }
          else
              url = require('@/logo/default.jpg'); // Default image. 
          return url;
      },
      generateReport (content) {                            
          this.$refs.html2Pdf.generatePdfProfile(content)          
      },
      getPartners() {        
        this.axios.get( this.$config.host+"/partners" ).then(response => (
          this.logos = response.data,
          this.logos.sort((a, b) => a.name > b.name ? 1 : -1),
          this.renderClasses(this.logos)          
        ));
      },
      renderClasses(logos) {
        logos.forEach(function callback(item) {
          if(item.class) {            
            if(item.class.length < 2) item.class = item.class.join(" ");
          }
        });          
        return logos;
      },     
      getTeams() { 
        let self = this;       
        this.axios.get( this.$config.host+"/teams" ).then(response => (           
          self.teams = response.data.filter((i) => {                          
            i.contact = self.convertLineBreak(i.contact);
            i.contact = self.URLify(i.contact);
            return i.published === true;
            //return this.user.type === 'admin';
            //return !this.teamCategory || (i.category_id === this.teamCategory);            
          })
        ));
      },      
      convertLineBreak(text) {        
        return text.replace(/\r\n/g, '<br />')        
      },      
      getTeamsAdmin() { 
        let self = this;       
        this.axios.get( this.$config.host+"/teams" ).then(response => (                                              
          self.teams = response.data.filter((i) => {            
            i.contact = self.convertLineBreak(i.contact);
            i.contact = self.URLify(i.contact);
            return self.nodeValidate(i);
            //return this.user.type === 'admin';
            //return !this.teamCategory || (i.category_id === this.teamCategory);            
          })          
        ));
      },            
      getCategories() {        
        this.axios.get( this.$config.host+"/categories" ).then(response => (
          this.categories = response.data,
          this.categories.sort((a, b) => a.name > b.name ? 1 : -1)                    
        ));
      },            
      filterPartner (value, search) {                
        return value != null && 
          search != null &&
          ( 
            ( typeof value === 'string' && value.toString().toLowerCase().indexOf(search.toLowerCase()) !== -1 ) ||
            ( typeof value === 'object' && value.some( item => { return item.name.toLowerCase().indexOf(search.toLowerCase()) > -1 } ) )
          )          
      }    
    },
    computed: {   
      user () {       
        return this.$store.getters.userdata
      },
      bgStyles() {
        return {
          "background-color": "#f0efef"          
        };
      },
      headers () {
        return [
          {
            text: 'Contact',
            align: ' d-none',
            value: 'contact'            
          },          
          {
            text: 'Resources',
            align: ' d-none',
            value: 'resources'            
          },                    
          {
            text: 'Focus',
            align: ' d-none',
            value: 'focus'            
          },                              
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
            width:'40%'
          },
          { 
            text: 'Classification', 
            sortable: false,
            value: 'category',
            width:'20%'
          },                    
          { 
            text: 'Institution',             
            value: 'partner[0].name',
            sortable: true,            
            width:'40%'
          },          
          { 
            text: '', 
            value: 'data-table-expand' 
          }
        ]
      },      
      headersProfile () {
        return [
          {
            text: 'Publ.',
            align: 'start',
            sortable: true,
            value: 'published',
            width:'4%'
          },
          {
            text: 'Name',            
            sortable: true,
            value: 'name',
            width:'28%'
          },
          { 
            text: 'Classification', 
            sortable: true,
            value: 'category',
            width:'20%'
          },                    
          { 
            text: 'Institution', 
            value: 'partner',
            width:'36%'
          },
          { 
            text: 'Owner', 
            value: 'owner',
            width:'10%'
          },          
          {
            text: 'Subm.',                        
            value: 'submitted',          
            sortable: false,            
            width:'2%',
            class: "greylight"
          },                              
          { 
            text: '', 
            value: 'data-table-expand' 
          }
        ]
      },            
      filteredTeams() {
        return this.teams.filter((i) => {
          return (!this.teamCategory && i.submitted === true) || 
                  (!this.teamCategory && i.owner === this.userid) ||
                  (i.category_id === this.teamCategory);          
        })
      }
    },
    mounted() {    
      setTimeout(() => {

        this.getCategories();
        this.getPartners();
        if(this.userid) {
          //console.log("mount mit userid");
          this.getTeamsAdmin()
        } else {
          //console.log("mount ohne userid");
          this.getTeams();   
        }          
        
      }, 200);
    }

  }
</script>

<style scoped>

.item {
    margin:60px 80px;
}
.item h2 {
    font-size:2.2rem;
}
.item p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: lightcyan;
}
.item table {
  width: 100%;
}
.logo img {
  border:1px solid #eee;  
  max-width: 100%;
}

.logo a {
  display:block;
  line-height: 0;
}
.logo a:hover,
.logo a.active {
  box-shadow: 0px 0px 2px rgba(70, 130, 180, 0.8);
}

.wrap {
  position:relative;
}

#mapinfo {
  display:block;
  position:absolute;
  padding:6px;
  min-width:180px;
  background-color: white;
  top:0px;
  left:0px;
  opacity: 0;
  z-index:8;
  font-size:.78rem;
  -webkit-transition: opacity .6s;
  -moz-transition: opacity .6s;
  -ms-transition: opacity .6s;
  -o-transition: opacity .6s;
  transition: opacity .6s;    
}

#mapinfo.visible {
  -webkit-transition: opacity .8s;
  -moz-transition: opacity .8s;
  -ms-transition: opacity .8s;
  -o-transition: opacity .8s;
  transition: opacity .8s;
  opacity: 1;
}

</style>

<style>

.admincontainer {
  max-width: 1240px;  
}
.greylight {
  background-color:#f9f9f9;
  opacity: .66;
}
.videodialog .v-card {
  line-height:0;
}
.videodialog video {
  max-width: 100%;
}
.videopreview img {
  max-width: 90%;
  height: auto;
}
.videopreview img:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {

  .researchtable > .v-data-table__wrapper table tr {
    display:flex;
  }
  .researchtable > .v-data-table__wrapper table tr td {
    font-size:0.7rem !important;
    padding:2px 4px !important;
    height:auto !important;
  }
  .researchtable > .v-data-table__wrapper table tr td:nth-child(1) {
    width:20%;
    background-color:#f9f9f9;
  }
  .researchtable > .v-data-table__wrapper table tr td:nth-child(2) {
    width:40%;
  }
  .researchtable > .v-data-table__wrapper table tr td:nth-child(3) { 
    width:30%;
    background-color:#f9f9f9;
  }
  .researchtable > .v-data-table__wrapper table tr td:nth-child(4) {
    width:10%;
  }

}

.researchtable > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;  
}
.researchtable > .v-data-table__wrapper tbody tr.v-data-table__expanded__content > td {
  border:0;
}
.researchtable > .v-data-table__wrapper > table > thead > tr > th {
  white-space: nowrap;
}
.researchtable > .v-data-table__wrapper > table .expandedinfo {
  font-size:.88rem;
}

.homebutton.v-btn.v-size--small {
  font-size: 0.7rem;
  color:#fff !important;
}

</style>