var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[(_vm.message)?_c('v-container',[_vm._v(" "+_vm._s(_vm.$router)+" ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$route.params.message)+" "),(_vm.user && _vm.user.id && (_vm.user.type == 'admin' || _vm.user.type == 'editor'))?_c('v-container',{staticClass:"admincontainer"},[(_vm.user.name)?_c('v-row',{staticClass:"mb-8"},[_c('v-col',{attrs:{"cols":"2","md":"2"}},[_c('sidemenu')],1),_c('v-col',{attrs:{"cols":"10","md":"10"}},[_c('div',{staticClass:"ml-4 mt-8 mb-4"},[_c('h2',[_vm._v("Users "),_c('v-btn',{staticClass:"userdisplay ml-2",attrs:{"depressed":"","small":""},on:{"click":function($event){_vm.openadduser = !_vm.openadduser;_vm.setPartner()}}},[_c('v-icon',{staticClass:"mr-2 mt-0",attrs:{"right":"","dark":""}},[_vm._v(" mdi-plus ")]),_c('div',{staticClass:"pt-1"},[_vm._v("Add user")])],1)],1)]),_c('Transition',{attrs:{"name":"fade"}},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.openadduser),expression:"openadduser"}],staticClass:"mx-1"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"rules":[_vm.rules.email],"placeholder":"Email","prepend-icon":"mdi-at"},model:{value:(_vm.adduser.email),callback:function ($$v) {_vm.$set(_vm.adduser, "email", $$v)},expression:"adduser.email"}}),(_vm.user.type == 'admin')?_c('v-autocomplete',{staticClass:"mt-0 ml-8 mb-0 py-2",attrs:{"items":_vm.partners,"dense":"","item-value":"id","item-text":"name","placeholder":"Select institution","background-color":"#f7f7f7","outlined":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.name)}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s( item.name.length > 40 ? item.name.substring(0,40)+"..." : item.name )}})]}}],null,false,2780272914),model:{value:(_vm.adduser.partner_id),callback:function ($$v) {_vm.$set(_vm.adduser, "partner_id", $$v)},expression:"adduser.partner_id"}}):(_vm.user.type == 'editor')?_c('v-text-field',{staticStyle:{"visibility":"hidden","height":"0"},model:{value:(_vm.adduser.partner_id),callback:function ($$v) {_vm.$set(_vm.adduser, "partner_id", $$v)},expression:"adduser.partner_id"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"4"}},[(_vm.user.type == 'editor' || _vm.user.type == 'admin')?_c('v-text-field',{attrs:{"rules":[_vm.rules.required],"placeholder":"Name"},model:{value:(_vm.adduser.name),callback:function ($$v) {_vm.$set(_vm.adduser, "name", $$v)},expression:"adduser.name"}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-btn',{staticClass:"mt-4",attrs:{"depressed":"","small":"","disabled":!_vm.valid},on:{"click":_vm.saveUser}},[_c('div',{staticClass:"pt-1"},[_vm._v("Add new editor")])])],1)],1)],1)],1),_c('div',[_c('v-data-table',{staticClass:"elevation-1 researchtable",attrs:{"headers":_vm.headers,"items":_vm.filteredUsers,"footer-props":{
                'items-per-page-options': [10, 20, 30, 40, 50]
              },"items-per-page":30,"single-expand":true,"search":_vm.search,"custom-filter":_vm.filterPartner,"show-expand":"","expanded":_vm.expanded,"item-key":"id"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
              var item = ref.item;
return [_c('tr',[_c('td',[(item['active'])?_c('span',{staticClass:"token"},[_vm._v("active")]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(item.name)+" ")]),_c('td',[(item['partner'])?_c('div',[_vm._v(" "+_vm._s(item['partner'][0].name)+" ")]):_vm._e()]),_c('td',{staticClass:"d-block d-sm-table-cell"},[(item['teams'])?_c('div',[_vm._v(" "+_vm._s(item['teams'][0].name)+" ")]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(item.email)+" ")]),_c('td',[_c('v-menu',{staticStyle:{"display":"inline-block"},attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","depressed":"","color":"rgba(0, 0, 0, 0.87)","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-title',[_vm._v("delete")]),((_vm.user.type === 'editor' && item.partner_id === _vm.user.partner_id) || (_vm.user.type === 'admin'))?_c('v-icon',{staticClass:"px-0 py-0 ml-2 mr-0 delete",staticStyle:{"display":"inline-block"},attrs:{"size":"14","color":"gray"},on:{"click":function($event){return _vm.deleteUser(item.id)}}},[_vm._v(" mdi-trash-can-outline ")]):_vm._e()],1)],1)],1)],1)])]}}],null,false,600552199)})],1)],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }