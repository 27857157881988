<template>
    <v-main id="contact">

      <div        
        class="mb-6 pt-4 pb-4"
        :style="bgStyles"
        >      
        <v-container>
            <v-row>
              <v-col
                cols="12"
                md="8"
                offset-md="2"
              >            
              <h1 class="ratitle">
              Service & Contact
              </h1>          
              <p>
                We would like to thank you for your interest in and support of the Brandenburg Health Sciences: Research Atlas. Your research profiles illustrate the innovative research approaches and offer a wealth of starting points for interdisciplinary collaborations. We would like to actively support you in this!
              </p>
              </v-col>          
            </v-row>
        </v-container>
      </div>


      <v-container>
        <v-row
          class="mb-8"
          >
          <v-col
            cols="12"
            md="8" 
            offset-md="2"             
            >                            
              <p>
                As support possibilities we see above all:
              </p>
              <ul>
                <li>
                  the identification and connection of potential partners from academia, industry and further relevant partners in the region 
                </li>
                <li>
                  the organisation and coordination of networking events
                </li>
                <li>
                  the identification of suitable funding instruments as well as support with project proposals.
                </li>
              </ul>
              <p>
                Please feel free to contact us or get in touch directly with the contact persons in the research and transfer department of your respective institution:
              </p>
              <blockquote>            

                <div
                  v-for="(contact, key) in contacts"
                  :key="key"
                  >
                  <p>{{ contact.name }}: <a :href="'mailto:'+contact.email">{{ contact.email }}</a></p>
                </div>                
                
              </blockquote>              
          </v-col>
        </v-row>
      </v-container>

      <v-container>
          <v-row class="text-center">

            <v-col 
              cols="12"
              md="12"
              >

            </v-col>      

          </v-row>
      </v-container>

    </v-main>
</template>

<script>

  export default {
    name: 'Contact',

    components: {
        //VueHtml2pdf
    },

    data: () => ({    
        search: '',        
        teams: [],     
        expanded: [],  
        pdfcontent: {}, 
        isExpanded: Boolean,
        contacts_src: [
          {
            name: 'Dr. Kathrin Reißig (MHB)',
            sort: 'MHB',
            email: 'kathrin.reissig@mhb-fontane.de'
          },
          {
            name: 'Dr. Tilo Wünsch (BTU)',
            sort: 'BTU',
            email: 'tilo.wuensch@b-tu.de'
          },
          {
            name: 'Dr. Günter Peine (FGW)',
            sort: 'FGW',
            email: 'guenter.peine@fgw-brandenburg.de'
          },
          {
            name: 'Tahani Adnan, M.Sc. (IAP)',
            sort: 'IAP',
            email: 'tahani.adnan@iap.fraunhofer.de'
          },
          {            
            name: 'Dr. Katharina Kasack (IZI-BB)',
            sort: 'IZI',
            email: 'katharina.kasack@izi-bb.fraunhofer.de'
          },
          {            
            name: 'Dr. Petra Wiedmer (DIfE)',
            sort: 'DIfE',
            email: 'petra.wiedmer@dife.de'
          },
          {            
            name: 'Dr. Frank Pawlitschek (HPI)',
            sort: 'HPI',
            email: 'Frank.Pawlitschek@hpi.de'
          },
          {            
            name: 'Prof. Dr. Julia Groß (HMU)',
            sort: 'HMU',
            email: 'julia.gross@health-and-medical-university.de'
          },
          {            
            name: 'Dr. Manfred Gossen (HZG)',
            sort: 'HZG',
            email: 'manfred.gossen@hereon.de'
          },
          {            
            name: 'Dr. Anna Sojka-Piotrowska (IHP)',
            sort: 'IHP',
            email: 'sojka@ihp-microelectronics.com'
          },
          {            
            name: 'Juliane Jury (MPI KG)',
            sort: 'MPI KG',
            email: 'juliane.jury@mpikg.mpg.de'
          },
          {            
            name: 'Dr. Ellen Zuther (ZKI-PH)',
            sort: 'ZKI-PH',
            email: 'ZutherE@rki.de'
          },
          {            
            name: 'Dr. Carsten Hille (TH Wildau)',
            sort: 'TH',
            email: 'carsten.hille@th-wildau.de'
          },
          {            
            name: 'Sascha Thormann (UP)',
            sort: 'UP',
            email: 'sascha.thormann@uni-potsdam.de'
          },
          {
            name: 'Lena Krippner (HC)',
            sort: 'HC',
            email: 'lena.krippner@uni-potsdam.de'
          },
          {
            name: 'Doreen Weber (EvB)',
            sort: 'EvB',
            email: 'doreen.weber@klinikumevb.de'
          }          
        ]      
    }),

    methods: {
      cover(url) {
          if (url !== ''){ // You can check any matching expression.
              try {
                  url = require('@/logo/' + url)
              } catch (e) {
                  url = require('@/logo/default.jpg'); // I used a default image. 
              }
          }
          else
              url = require('@/logo/default.jpg'); // Default image. 
          return url;
      },
      sort (arr) {
        arr.sort(function (a, b) {
          if (a.sort < b.sort) {
            return -1;
          }
          if (a.sort > b.sort) {
            return 1;
          }
          return 0;
        });
        return arr;
      }
    },
    computed: {      
      bgStyles() {
        return {
          "background-color": "#f0efef"          
        };
      },
      headers () {
        return [
          {
            text: 'Name',
            align: 'start',
            sortable: true,
            value: 'name',
            width:'50%'
          },
          { 
            text: 'Institution', 
            value: 'partner',
            width:'50%'
          },          
          { 
            text: '', 
            value: 'data-table-expand' 
          }
        ]
      }, 
      contacts () {        
        // this.contacts_src.sort((a,b) => a.name > b.name ? 1 : -1)        
        return this.sort(this.contacts_src);
      }    
    },
    mounted() {
      console.log("mount List / Home");      
    }

  }
</script>

<style scoped>

.item {
    margin:60px 80px;
}
.item h2 {
    font-size:2.2rem;
}
.item p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    background-color: lightcyan;
}
.item table {
  width: 100%;
}
.item table td {
  border:1px solid lightblue;
  background-color: yellow;
}
.logo img {
  border:1px solid #eee;  
  max-width: 100%;
}

</style>

<style>

#contact ul {
  margin:0 0 34px 15px;
}
#contact ul li {
  padding:0 10px;
}
#contact blockquote {  
  margin-left:50px;
  padding-top:6px;
}
#contact blockquote > div::after {    
  content: " ";
  display:block;
  height:0;
  clear:both;
}

#contact blockquote > div > p {  
  line-height:1.5;
  width:100%;
  margin: 0 0 4px;
}

#contact blockquote > div > p > a {  
  display: block;
  float: right;
  width:50%;
}

.researchtable > .v-data-table__wrapper tbody tr.v-data-table__expanded__content {
  box-shadow: none !important;  
}
.researchtable > .v-data-table__wrapper tbody tr.v-data-table__expanded__content > td {
  border:0;
}
.researchtable > .v-data-table__wrapper > table > thead > tr > th {
  white-space: nowrap;
}

</style>