import Vue from 'vue';
import Vuex from 'vuex';

import access from './access';

Vue.use(Vuex);

export default new Vuex.Store({

  state: {
    user: {
      id: null,
      name: null,
      category: null,
      email: null
    },
    access: {
    },
    search: {
      mode: 0,
      content: null,
      cat: null
    },
    node: false
  },
  getters: {
    userdata: state => {
      return state.user
    },
    node: state => {
      return state.node
    }
  },
  mutations: {
    setUser (state, userdata) {      
      state.access = new access(userdata);      
      state.user = userdata;      
    },
    setNode (state, nodedata) {      
      //console.log("mutations setNode:");
      state.access = new access(null);      
      state.node = nodedata;      
      console.log(state.node);
    },    
    clearUser (state) {
      //console.log("mutations clearUser:");
      //state.access = new access(null);      
      state.user = {};
    },    
    setSearchMode(state, mode) {
      state.search.mode = mode;            
    },    
    setSearchContent(state, content) {
      state.search.content = content;
      //state.search.cat = cat;
    }
  }

});
